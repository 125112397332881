import React from 'react';
import cx from 'classnames';

import ChevronS2Right from '../Icons/ChevronS2Right';
import ChevronS2Left from '../Icons/ChevronS2Left';
import Touchable, { TouchableProps } from '../Touchable';

import styles from './PrimaryLink.module.scss';

export interface Props extends TouchableProps {
  back?: boolean;
}

export const PrimaryLink: React.FC<Props> = ({
  back,
  children,
  className,
  disabled,
  ...restProps
}) => (
  <Touchable
    {...restProps}
    className={cx(
      styles.baseLink,
      styles.primaryLink,
      disabled && styles.disabled,
      className,
    )}
  >
    {back && <ChevronS2Left className={styles.leftChevron} />}
    {children}
    {!back && <ChevronS2Right className={styles.rightChevron} />}
  </Touchable>
);

PrimaryLink.displayName = 'PrimaryLink';
export default PrimaryLink;
export { styles };

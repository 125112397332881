import { PrismTheme } from 'prism-react-renderer';

import colors from '../styles/colors';

const theme: PrismTheme = {
  plain: {
    backgroundColor: colors.black,
    color: colors.black200,
  },
  styles: [
    {
      types: ['prolog', 'doctype', 'cdata', 'punctuation', 'operator'],
      style: {
        color: colors.black700,
      },
    },
    {
      types: ['comment'],
      style: {
        color: colors.black500,
      },
    },
    {
      types: ['tag'],
      style: {
        color: colors.blue600,
      },
    },
    {
      types: ['number'],
      style: {
        color: colors.yellow600,
      },
    },
    {
      types: ['attr-value'],
      style: {
        color: colors.purple400,
      },
    },
    {
      types: ['string'],
      style: {
        color: colors.green400,
      },
    },
    {
      types: ['keyword', 'tag', 'attr-name', 'function', 'variable'],
      style: {
        color: colors.blue600,
      },
    },
    // catch all
    {
      types: [
        'builtin',
        'constant',
        'boolean',
        'entity',
        'url',
        'control',
        'directive',
        'unit',
        'statement',
        'regex',
        'at-rule',
        'placeholder',
        'tag-id',
        'selector',
        'atrule-id',
        'property',
      ],
      style: {
        color: colors.red400,
      },
    },
    {
      types: ['deleted'],
      style: {
        textDecorationLine: 'line-through',
      },
    },
    {
      types: ['inserted'],
      style: {
        textDecorationLine: 'underline',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['boolean', 'namespace'],
      style: {
        color: colors.red600,
      },
    },
    {
      types: ['important', 'bold'],
      style: {
        fontWeight: 'bold',
      },
    },
    {
      types: [
        'important',
        'class-member',
        'class-name',
        'known-class-name',
        'maybe-class-name',
      ],
      style: {
        color: colors.blue600,
      },
    },
  ],
};

export default theme;

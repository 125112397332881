import cx from 'classnames';
import React, { CSSProperties, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './Tooltip.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  id?: string;
  block?: boolean;
  placement: 'right' | 'left' | 'top' | 'bottom' | 'top-end' | 'bottom-end';
  showOn: 'hover' | 'click' | 'focus';
  style?: CSSProperties;
  target: React.ReactElement;
  wrapperClassName?: Parameters<typeof cx>[0];
  clickable?: boolean;
  // https://popper.js.org/docs/v2/modifiers/offset/
  offset?: [number, number];
  onShow?: () => void;
  onHide?: () => void;
  onEscape?: () => void;
}

interface WrapperProps {
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Tooltip: React.FC<Props> = props => {
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );
  const popperPlacement = (placement => {
    switch (placement) {
      case 'right':
        return 'right-start';
      case 'left':
        return 'left-start';
      default:
        return placement;
    }
  })(props.placement);

  const popper = usePopper(targetElement, tooltipElement, {
    strategy: 'fixed',
    placement: popperPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          // https://popper.js.org/docs/v2/modifiers/offset/
          offset: props.offset || [0, 10],
        },
      },
    ],
  });

  const [show, setShow] = React.useState(false);

  const showTooltip = () => {
    if (!show) {
      props.onShow && props.onShow();
      setShow(true);
    }
  };
  const hideTooltip = () => {
    if (show) {
      props.onHide && props.onHide();
      setShow(false);
    }
  };

  const toggleShowTooltip = () => {
    if (show) {
      hideTooltip();
    } else {
      showTooltip();
    }
  };

  useEffect(() => {
    const onTooltipMouseEnter = () => showTooltip();
    const onTooltipMouseLeave = () => hideTooltip();

    if (props.clickable && tooltipElement) {
      tooltipElement.addEventListener('mouseenter', onTooltipMouseEnter);
      tooltipElement.addEventListener('mouseleave', onTooltipMouseLeave);
    }

    /**
     * Buggy: this is triggered when interactive elements in the Tooltip are clicked as well because
     * event.currentTarget is always `document`
     */
    const clickOutsideHandler = (event: MouseEvent) => {
      if (
        tooltipElement &&
        event.target instanceof Node &&
        !tooltipElement.contains(event.target)
      ) {
        props.onEscape && props.onEscape();
        hideTooltip();
      }
    };

    const escapeKeyHandler = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        props.onEscape && props.onEscape();
        hideTooltip();
      }
    };

    // For tooltips that show/hide on target click, also handle escaping via outside click or escape key
    if (props.showOn === 'click') {
      document.addEventListener('click', clickOutsideHandler);
      document.addEventListener('keyup', escapeKeyHandler);
    }

    return () => {
      if (props.clickable && tooltipElement) {
        tooltipElement.removeEventListener('mouseenter', onTooltipMouseEnter);
        tooltipElement.removeEventListener('mouseleave', onTooltipMouseLeave);
      }

      if (props.showOn === 'click') {
        document.removeEventListener('click', clickOutsideHandler);
        document.removeEventListener('keyup', escapeKeyHandler);
      }
    };
  });

  const wrapperProps: WrapperProps = {};
  switch (props.showOn) {
    case 'click':
      wrapperProps.onClick = toggleShowTooltip;
      break;
    case 'focus':
      wrapperProps.onFocus = showTooltip;
      wrapperProps.onBlur = hideTooltip;
      break;
    case 'hover':
      wrapperProps.onMouseEnter = showTooltip;
      wrapperProps.onMouseLeave = hideTooltip;
  }

  return (
    <>
      <div
        className={cx(
          styles.wrapper,
          props.block && styles.block,
          props.wrapperClassName,
        )}
        ref={setTargetElement}
        {...wrapperProps}
      >
        {props.target}
      </div>
      <div
        style={popper.styles.popper}
        className={cx(
          styles.tooltip,
          props.showOn !== 'click' &&
            props.clickable &&
            styles.tooltipClickable,
          show && styles.showTooltip,
          props.className,
        )}
        role='tooltip'
        {...popper.attributes.popper}
        ref={setTooltipElement}
      >
        {props.children}
      </div>
    </>
  );
};

Tooltip.displayName = 'Tooltip';
export default Tooltip;
export { styles };

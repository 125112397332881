import React from 'react';
import cx from 'classnames';

import ChevronS2Right from '../Icons/ChevronS2Right';
import ChevronS2Left from '../Icons/ChevronS2Left';
import Touchable, { TouchableProps } from '../Touchable';

import primaryLinkStyles from '../PrimaryLink/PrimaryLink.module.scss';
import styles from './NavigationLink.module.scss';

export interface Props extends TouchableProps {
  forward?: boolean;
}

export const NavigationLink: React.FC<Props> = ({
  forward,
  children,
  className,
  disabled,
  ...restProps
}) => (
  <Touchable
    {...restProps}
    className={cx(
      primaryLinkStyles.baseLink,
      styles.navigationLink,
      disabled && primaryLinkStyles.disabled,
      className,
    )}
  >
    {!forward ? (
      <ChevronS2Left className={cx(primaryLinkStyles.leftChevron)} />
    ) : null}
    {children}
    {forward ? (
      <ChevronS2Right className={cx(primaryLinkStyles.rightChevron)} />
    ) : null}
  </Touchable>
);

NavigationLink.displayName = 'NavigationLink';
export default NavigationLink;
export { styles };

import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Lightning: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M42.477 22.692c2.064 0 3.252 2.036 2.03 3.479l-25.36 29.925c-1.68 1.983-5.268.417-4.436-1.934l5.774-16.322H4.522c-2.018 0-3.216-1.958-2.085-3.409l24.528-31.46c1.584-2.03 5.23-.605 4.524 1.77l-5.333 17.951h16.321z'
        stroke='currentColor'
        strokeWidth={4}
        fill='none'
        fillRule='evenodd'
      />
    </svg>
  </i>
);

Lightning.displayName = 'Lightning';
export default Lightning;

import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import baseStyles from '../BaseInput/BaseInput.module.scss';
import styles from './TextArea.module.scss';

export interface Props {
  className?: Parameters<typeof cx>[0];
  errorMessage?: string;
  id: string;
  label: string;
  focused?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  ref?: any; // React doesnt expose a type for this
  inputRef?: () => void;
  minRows?: number;
  maxRows?: number;
  tooltip?: string;
  isDarkMode?: boolean;
}

export const TextArea: FunctionComponent<Props> = ({
  className,
  errorMessage,
  id,
  label,
  focused,
  name,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  readOnly,
  required,
  value,
  ref,
  inputRef,
  minRows = 5,
  maxRows,
  tooltip,
  isDarkMode,
}) => {
  const floatLabel = Boolean(value) || focused;
  const invalid = Boolean(errorMessage);
  return (
    <fieldset
      className={cx(
        styles.container,
        isDarkMode && styles.containerDark,
        readOnly && baseStyles.readOnly,
        className,
      )}
    >
      <label
        className={cx(
          baseStyles.label,
          isDarkMode && baseStyles.labelDark,
          (floatLabel || placeholder) && [
            baseStyles.floatLabel,
            isDarkMode && baseStyles.floatLabelDark,
          ],
        )}
        htmlFor={id}
      >
        {invalid ? errorMessage : label}
      </label>
      <TextareaAutosize
        className={cx(
          baseStyles.input,
          styles.input,
          isDarkMode && baseStyles.inputDark,
          invalid && baseStyles.hasError,
        )}
        {...{
          value,
          name,
          onChange,
          onFocus,
          onBlur,
          readOnly,
          required,
          ref,
          inputRef,
          minRows,
          maxRows,
          placeholder,
        }}
        id={id}
      />
      {tooltip && <div className={cx(baseStyles.tooltip)}>{tooltip}</div>}
    </fieldset>
  );
};

TextArea.displayName = 'TextArea';

export default TextArea;
export { styles };

import * as componentStyles from './criticalCssImportMap';

interface WithInjectedGetCss {
  _getCss: () => string;
}

/**
 * This util function should only be used by repos relying on server-side rendering (SSR) with
 * access to a post-build or distribution version of Threads. The type casting here is intentional.
 *
 * We inject a _getCss method to component stylesheets (see the `injectStyleFunctions` plugin in
 * rollup.config.js) to retrieve the post-build CSS output in order to allow repos that rely on
 * SSR (e.g. repos using NextJS) to extract post-build CSS as stylesheets and serve them from the
 * server. This is to primarily address any Flash of Unstyled Content (FOUC) issues that can come
 * with only using a dynamic load of the Threads library.
 *
 * TODO(aren): Improve upon this method by passing taking a `html` param so that we only return
 * the CSS for classnames.
 * TODO(aren): Include CSS from SCSS-only modules (e.g. Color)
 */
export function criticalCss(components?: string[]) {
  const criticalStyles: Array<WithInjectedGetCss> = [];
  // Ignore non-existant components and components that we don't have exported styles for
  const filteredComponents =
    components != null && components.length > 0
      ? components.filter(component =>
          Object.keys(componentStyles).includes(component),
        )
      : [];

  if (filteredComponents.length > 0) {
    filteredComponents.forEach(component => {
      criticalStyles.push(componentStyles[component]);
    });
  } else {
    // If no components were specified or if all components specified were invalid return CSS for
    // every component
    Object.keys(componentStyles).forEach(component => {
      criticalStyles.push(componentStyles[component]);
    });
  }

  // Return all critical CSS as a single string
  return criticalStyles.map(styles => styles._getCss()).join('\n');
}

import React from 'react';
import ReactSelect, {
  Props as ReactSelectProps,
  components,
} from 'react-select';
import cx from 'classnames';

import ChevronS1Down from '../Icons/ChevronS1Down';
import CloseS2 from '../Icons/CloseS2';
import CloseS1 from '../Icons/CloseS1';
import Checkmark from '../Icons/Checkmark';

import reactSelectStyles from './Select.styles';
import baseStyles from '../BaseInput/BaseInput.module.scss';
import styles from './Select.module.scss';

interface OverrideProps {
  label: string;
  id: string;
  errorMessage?: string;
}

interface OwnProps {
  controlClassName?: Parameters<typeof cx>[0];
}

type Props = Pick<
  ReactSelectProps,
  Exclude<keyof ReactSelectProps, 'id' | 'label'>
> &
  OverrideProps &
  OwnProps;

// @ts-ignore
const Control = props => (
  <components.Control
    className={props.selectProps.controlClassName}
    {...props}
  />
);

// @ts-ignore
const SelectContainer = props => (
  <fieldset
    className={cx(
      baseStyles.container,
      props.selectProps.disabled && baseStyles.readOnly,
      props.selectProps.className,
    )}
  >
    <components.SelectContainer {...props} className={null}>
      <label
        className={cx(
          baseStyles.label,
          props.selectProps.isDarkMode && baseStyles.labelDark,
          (props.hasValue ||
            props.isFocused ||
            props.selectProps.errorMessage ||
            props.selectProps.placeholder) && [
            baseStyles.floatLabel,
            props.selectProps.isDarkMode && baseStyles.floatLabelDark,
          ],
        )}
        htmlFor={props.selectProps.id}
      >
        {props.selectProps.errorMessage
          ? props.selectProps.errorMessage
          : props.selectProps.label}
      </label>
      {props.children}
    </components.SelectContainer>
  </fieldset>
);

// @ts-ignore
const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ChevronS1Down />
  </components.DropdownIndicator>
);

// @ts-ignore
const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <CloseS1 />
  </components.ClearIndicator>
);

// @ts-ignore
const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <CloseS2 className={cx(styles.multiValueRemove)} />
  </components.MultiValueRemove>
);

// @ts-ignore
const Option = props => (
  <components.Option {...props}>
    {props.children} {props.isSelected ? <Checkmark /> : null}
  </components.Option>
);

// @ts-ignore
const Input = props => (
  <components.Input {...props} className={cx(props.className, styles.input)} />
);

export const Select: React.FC<Props> = props => (
  <ReactSelect
    {...props}
    blurInputOnSelect={!props.isMulti ? true : false}
    placeholder={props.placeholder ? props.placeholder : ''}
    isClearable={props.isClearable}
    isDisabled={props.disabled}
    openMenuOnFocus={true}
    tabSelectsValue={false}
    closeMenuOnSelect={
      props.closeMenuOnSelect != null ? props.closeMenuOnSelect : !props.isMulti
    }
    isDarkMode={props.isDarkMode}
    styles={reactSelectStyles}
    components={{
      ClearIndicator,
      Control,
      DropdownIndicator,
      Input,
      MultiValueRemove,
      Option,
      SelectContainer,
    }}
  />
);

Select.displayName = 'Select';
export default Select;
export { styles };

import React from 'react';
import cx from 'classnames';

import Button from '../Button';

import styles from './SelectGroup.module.scss';

// We expose an API as close to the react-select API as possible, and even use its types
export interface SelectGroupOptionsType {
  label: string;
  value: any;
  disabled?: boolean;
}

interface CommonSelectGroupProps {
  className?: Parameters<typeof cx>[0];
  buttonClassName?: Parameters<typeof cx>[0];
  unselectedButtonClassName?: Parameters<typeof cx>[0];
  inline?: boolean;
  labeledById?: string;
  options: SelectGroupOptionsType[];
  small?: boolean;
  tabIndex?: number;
}

export interface SingleSelectGroupProps extends CommonSelectGroupProps {
  multi?: false;
  value: SelectGroupOptionsType | null | undefined;
  onChange: (value: SelectGroupOptionsType) => void;
}

export interface MultiSelectGroupProps extends CommonSelectGroupProps {
  multi: true;
  value: SelectGroupOptionsType[] | null | undefined;
  onChange: (value: SelectGroupOptionsType[]) => void;
}

type Props = SingleSelectGroupProps | MultiSelectGroupProps;

const isMultiOptionSelected = (
  value: MultiSelectGroupProps['value'],
  option: SelectGroupOptionsType,
): boolean =>
  value != null && Boolean(value.find(v => option.value === v.value));

const isSingleOptionSelected = (
  value: SingleSelectGroupProps['value'],
  option: SelectGroupOptionsType,
): boolean => value != null && option.value === value.value;

const multiOptionOnClick = (
  value: MultiSelectGroupProps['value'],
  option: SelectGroupOptionsType,
  selected: boolean,
  onChange: MultiSelectGroupProps['onChange'],
) => () =>
  value != null && selected
    ? // filter out option from current value as its being unselected
      onChange(value.filter(v => v.value !== option.value))
    : // concat option to current value
      onChange(value != null ? [...value, option] : [option]);

const singleOptionOnClick = (
  option: SelectGroupOptionsType,
  selected: boolean,
  onChange: SingleSelectGroupProps['onChange'],
) => () => (selected ? null : onChange(option));

// you lose some type checking when destructuring props that are an intersection type
// https://github.com/Microsoft/TypeScript/issues/27927#issuecomment-442921711
export const SelectGroup: React.FC<Props> = props => (
  <div
    role={props.multi ? 'group' : 'radiogroup'}
    aria-labelledby={props.labeledById}
    className={cx(styles.selectGroup, props.className)}
  >
    {props.options.map((option, i) => {
      const selected =
        props.multi === true
          ? isMultiOptionSelected(props.value, option)
          : isSingleOptionSelected(props.value, option);
      const onClick =
        props.multi === true
          ? multiOptionOnClick(props.value, option, selected, props.onChange)
          : singleOptionOnClick(option, selected, props.onChange);
      return (
        <Button
          key={i}
          role={props.multi ? 'checkbox' : 'radio'}
          ariaChecked={selected}
          tabIndex={props.tabIndex != null ? props.tabIndex + i : undefined}
          className={cx(
            styles.selectGroupItem,
            !props.inline && !props.multi && styles.selectGroupItemFlex,
            props.multi && styles.selectGroupItemMulti,
            props.buttonClassName,
            !selected && props.unselectedButtonClassName,
          )}
          onClick={onClick}
          secondary={!selected}
          disabled={option.disabled}
          centered
          inline={props.multi || props.inline}
          small={props.multi || props.small}
        >
          {option.label}
        </Button>
      );
    })}
  </div>
);

SelectGroup.displayName = 'SelectGroup';
export default SelectGroup;
export { styles };

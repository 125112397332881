import React from 'react';
import cx from 'classnames';

import IconButton from '../IconButton';
import CloseS2 from '../Icons/CloseS2';

import styles from './Label.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  onClick: () => void;
}

export const Label: React.FC<Props> = ({ children, className, onClick }) => (
  <span className={cx(styles.label, className)}>
    {children}{' '}
    <IconButton
      accessibilityLabel='Remove'
      icon={<CloseS2 />}
      onClick={onClick}
    />
  </span>
);

Label.displayName = 'Label';
export default Label;
export { styles };

import React from 'react';
import cx from 'classnames';

import Button from '../Button';

import styles from './Banner.module.scss';

interface Props {
  buttonText?: string;
  children: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  error?: boolean;
  onClick?: () => void;
  title?: string;
}

export const Banner: React.FC<Props> = ({
  buttonText,
  children,
  className,
  error,
  onClick,
  title,
}) => (
  <div
    role='alert'
    className={cx(styles.banner, error && styles.error, className)}
  >
    <div className={styles.content}>
      {title && <h6 className={styles.title}>{title}</h6>}
      <p className={styles.text}> {children}</p>
    </div>
    {onClick != null ? (
      <Button
        secondary
        small
        inline
        centered
        onClick={onClick}
        className={styles.button}
      >
        {buttonText}
      </Button>
    ) : null}
  </div>
);

Banner.displayName = 'Banner';
export default Banner;

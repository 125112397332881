import React, { ChangeEvent } from 'react';
import cx from 'classnames';

import baseStyles from '../BaseInput/BaseInput.module.scss';
import styles from './Checkbox.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  disabled?: boolean;
  id: string;
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  small?: boolean;
}

export const Checkbox: React.FC<Props> = ({
  children,
  className,
  value,
  disabled,
  id,
  onChange,
  small,
}) => (
  <div className={cx(baseStyles.container, styles.container, className)}>
    <input
      className={cx(styles.checkbox)}
      checked={value}
      disabled={disabled}
      type='checkbox'
      id={id}
      onChange={onChange}
    />
    <label
      className={cx(
        styles.label,
        small && styles.small,
        disabled && styles.disabled,
      )}
      htmlFor={id}
    >
      {children}
    </label>
  </div>
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
export { styles };

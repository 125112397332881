import React from 'react';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import cx from 'classnames';

import CodeBlock, { Props as CodeBlockProps } from '../CodeBlock';
import styles from '../CodeBlock/CodeBlock.module.scss';

import theme from './CodeBlockHighlighted.styles';

interface Props extends CodeBlockProps {
  code: string;
  lang: Language | 'ruby' | 'java';
}

export const CodeBlockHighlighted: React.FC<Props> = ({
  code,
  lang,
  wrap,
  ...restProps
}) => (
  <CodeBlock code={null} {...restProps}>
    <Highlight
      {...defaultProps}
      theme={theme}
      code={code}
      language={
        // temporary hack, see https://github.com/FormidableLabs/prism-react-renderer/pull/64
        lang === 'ruby' ? 'python' : lang === 'java' ? 'typescript' : lang
      }
      children={({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className={styles.container}>
          <div className={styles.lineNumbers}>
            {tokens.map((line, i) => (
              <div key={i}>{i + 1}</div>
            ))}
          </div>
          <pre
            className={cx(
              styles.pre,
              wrap ? styles.wrap : styles.noWrap,
              className,
            )}
            style={style}
          >
            <div className={cx(styles.code)}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </div>
          </pre>
        </div>
      )}
    />
  </CodeBlock>
);

CodeBlockHighlighted.displayName = 'CodeBlockHighlighted';
export default CodeBlockHighlighted;
export { styles };

import { Styles } from 'react-select';
import colors from '../styles/colors';

const units = (i: number) => `${(i * 0.8).toFixed(1)}rem`;

const styles: Styles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    alignItems: 'flex-start',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 0,
    color: colors.black600,
    ':hover': {
      color: colors.blue800,
    },
  }),
  container: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode
      ? colors.black1000
      : colors.white,
    minHeight: units(6),
  }),
  control: (provided, state) => {
    const borderColor = state.isFocused
      ? colors.blue800
      : state.selectProps.isDarkMode
      ? colors.black400
      : colors.black600;
    return {
      ...provided,
      position: 'unset',
      minHeight: units(6),
      backgroundColor: 'transparent',
      borderColor: state.selectProps.errorMessage ? colors.red800 : borderColor,
      borderRadius: '0.2rem',
      color: state.selectProps.isDarkMode ? colors.black400 : 'inherit',
      paddingTop: '0.3rem',
      paddingBottom: '0.3rem',
      // borderBottomRightRadius: state.isFocused ? 0 : undefined,
      // borderBottomLeftRadius: state.isFocused ? 0 : undefined,
      cursor: 'pointer',
      boxShadow: 'none',
      ':hover': {
        borderColor: state.selectProps.errorMessage
          ? colors.red800
          : borderColor,
      },
    };
  },
  input: (provided, state) => ({
    marginLeft: units(1),
    fontFamily: 'Cern, Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    color: colors.black600,
    // transition: 'transform 350ms cubic-bezier(0.23, 1.2, 0.32, 1)',
    transform: state.isFocused
      ? 'rotate3d(1, 0, 0, 180deg)'
      : 'rotate3d(1, 0, 0, 0deg)',
  }),
  group: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDarkMode ? colors.black400 : colors.black1000,
    paddingBottom: 0,
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    paddingLeft: units(2),
    color: 'inherit',
    fontFamily: 'Inconsolata, Helvetica, Arial, sans-serif',
    fontSize: '1.4rem',
    lineHeight: 1.33,
    letterSpacing: 1,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.black200,
    marginBottom: 0,
    paddingBottom: units(1),
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode
      ? colors.black1000
      : colors.white,
    top: 'unset',
    marginTop: -2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
    border: '1px',
    borderStyle: 'solid',
    borderColor: state.selectProps.errorMessage
      ? colors.red800
      : colors.blue800,
    borderTop: 0,
    boxShadow: '0 0.2rem 0.4rem 0 rgba(17, 17, 17, 0.08)',
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: state.selectProps.isDarkMode
      ? colors.black800
      : colors.black200,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDarkMode
      ? colors.black900
      : colors.black100,
    height: units(4),
    display: 'flex',
    alignItems: 'center',
    marginTop: units(0.5),
    marginRight: units(1),
    marginBottom: units(0.5),
    marginLeft: 0,
    cursor: 'default',
    color: state.selectProps.isDarkMode ? colors.black500 : colors.black1000,
    position: 'relative',
    ':hover': {
      color: colors.blue800,
    },
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'inherit',
    paddingLeft: units(1),
    fontSize: units(2),
    paddingRight: units(3.5),
    marginLeft: 0,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: 0,
    color: state.selectProps.isDarkMode ? colors.black400 : colors.black1000,
    cursor: 'pointer',
    ':hover': {
      color: colors.blue800,
      backgroundColor: 'transparent',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDarkMode ? colors.black400 : colors.black1000,
    paddingLeft: units(2),
    paddingRight: units(2),
    paddingTop: 0,
    paddingBottom: 0,
    height: units(6),
    lineHeight: units(6),
  }),
  option: (provided, state) => ({
    ...provided,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: state.selectProps.isDarkMode ? colors.black400 : colors.black1000,
    paddingLeft: units(2),
    paddingRight: units(1),
    paddingTop: units(2),
    paddingBottom: units(2),
    minHeight: units(6),
    lineHeight: units(3),
    cursor: 'pointer',
    backgroundColor: state.isFocused
      ? state.selectProps.isDarkMode
        ? colors.black900
        : colors.black100
      : 'transparent',
    ':hover': {
      backgroundColor: state.selectProps.isDarkMode
        ? colors.black900
        : colors.black100,
    },
    ':hover:last-child': {
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 'normal',
    color: colors.black600,
    marginLeft: 0,
    paddingLeft: units(1),
    pointerEvents: 'none',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDarkMode ? colors.black400 : colors.black1000,
    marginLeft: units(1),
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: units(1),
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export default styles;

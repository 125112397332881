import React from 'react';
import cx from 'classnames';

import styles from './Column.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  centeredContent?: boolean;
  small?: number | string;
  medium?: number | string;
  large?: number | string;
  xlarge?: number | string;
  smallOrder?: number;
  mediumOrder?: number;
  largeOrder?: number;
  xlargeOrder?: number;
  noMarginRight?: boolean;
  allowOverflow?: boolean;
}

export const Column: React.FC<Props> = ({
  children,
  className,
  centeredContent,
  noMarginRight,
  allowOverflow,
  ...widths
}) => {
  let widthClasses: string[] = [];
  Object.entries(widths).map(width => {
    let [viewportSize, cols] = width;
    if (['hide', 'auto'].includes(cols)) {
      cols = `${cols.charAt(0).toUpperCase()}${cols.slice(1)}`;
    }
    widthClasses = [...widthClasses, styles[`${viewportSize}${cols}`]];
    return widthClasses;
  });
  return (
    <div
      className={cx(
        styles.column,
        className,
        widthClasses,
        centeredContent && styles.centeredContent,
        noMarginRight && styles.noMarginRight,
        allowOverflow && styles.allowOverflow,
      )}
    >
      {children}
    </div>
  );
};

Column.displayName = 'Column';
export default Column;
export { styles };

import React from 'react';
import cx from 'classnames';

import ChevronS2Down from '../Icons/ChevronS2Down';
import ChevronS2Up from '../Icons/ChevronS2Up';
import Touchable, { TouchableProps } from '../Touchable';
import styles from './TextDropdownButton.module.scss';

export interface Props extends TouchableProps {
  open: boolean;
}

export const TextDropdownButton: React.FC<Props> = ({
  open,
  children,
  className,
  disabled,
  ...restProps
}) => {
  return (
    <Touchable
      {...restProps}
      className={cx(
        styles.baseLink,
        styles.textDropdownButton,
        disabled && styles.disabled,
        className,
      )}
    >
      {children}
      {!open && <ChevronS2Down className={styles.downChevron} />}
      {open && <ChevronS2Up className={styles.upChevron} />}
    </Touchable>
  );
};

TextDropdownButton.displayName = 'TextDropdownButton';
export default TextDropdownButton;
export { styles };

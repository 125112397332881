import React from 'react';
import cx from 'classnames';

import { SingleSelectGroupProps } from '../SelectGroup';

import SelectGroup from '../SelectGroup';
import styles from './ButtonToggle.module.scss';

type Props = Pick<
  SingleSelectGroupProps,
  'options' | 'onChange' | 'className' | 'value'
>;

export const ButtonToggle: React.FC<Props> = ({ className, ...restProps }) => (
  <SelectGroup
    inline
    small
    className={cx(styles.buttonToggle, className)}
    buttonClassName={styles.buttonToggleItem}
    unselectedButtonClassName={styles.buttonToggleItemUnselected}
    {...restProps}
  />
);

ButtonToggle.displayName = 'ButtonToggle';
export default ButtonToggle;
export { styles };

interface Country {
  code: string;
  name: string;
  icon: string;
  termsURL: string;
  privacyPolicyURL: string;
}

const LEGAL_URL = 'https://plaid.com/legal';
const PRIVACY_POLICY_URL = `${LEGAL_URL}#end-user-privacy-policy`;

export const SUPPORTED_COUNTRIES: Country[] = [
  {
    code: 'US',
    name: 'United States',
    icon: '🇺🇸',
    termsURL: `${LEGAL_URL}/end-user-services-agreement`,
    privacyPolicyURL: PRIVACY_POLICY_URL,
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    icon: '🇬🇧',
    termsURL: `${LEGAL_URL}#end-user-services-agreement-uk`,
    privacyPolicyURL: PRIVACY_POLICY_URL,
  },
];

export const DEFAULT_COUNTRY = SUPPORTED_COUNTRIES[0];

// TODO: get rid of libphonenumber dependency
// const defaultPhoneNumber = `+${getCountryCallingCode(DEFAULT_COUNTRY.code)}`;
const defaultPhoneNumber = `+1`;

export const DEFAULT_PHONE_NUMBER = {
  readable: defaultPhoneNumber,
  e164: defaultPhoneNumber,
  valid: false,
};

import React, { FocusEvent, ChangeEvent, KeyboardEvent } from 'react';
import cx from 'classnames';

import styles from './BaseInput.module.scss';

export interface InputProps {
  autoCapitalize?: string;
  autoComplete?: string;
  autoCorrect?: string;
  autoFocus?: boolean;
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  codeType?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  focused?: boolean;
  iconButtons?: React.ReactElement[];
  inputMode?:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';
  id: string;
  isDarkMode?: boolean;
  label: string;
  large?: boolean;
  maxLength?: number;
  minLength?: number;
  name?: string;
  note?: React.ReactNode;
  noteIcon?: React.ReactElement;
  noteId?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  pattern?: string;
  placeholder?: string;
  prefix?: (callbacks: {
    onFocus: () => void;
    onBlur: () => void;
  }) => React.ReactElement;
  readOnly?: boolean;
  ref?: any;
  required?: boolean;
  value?: string;
}

interface Props extends InputProps {
  type: string;
}

export const BaseInput: React.ForwardRefExoticComponent<
  Props
> = React.forwardRef(
  (
    {
      autoCapitalize,
      autoComplete,
      autoCorrect,
      autoFocus,
      children,
      className,
      codeType,
      disabled,
      errorMessage,
      focused,
      iconButtons,
      id,
      inputMode,
      isDarkMode,
      label,
      large,
      maxLength,
      minLength,
      name,
      note,
      noteIcon,
      noteId,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      pattern,
      placeholder,
      prefix,
      readOnly,
      required,
      type,
      value,
    },
    ref: any,
  ) => {
    const floatLabel = Boolean(value || errorMessage || focused || placeholder);
    const invalid = Boolean(errorMessage);

    return (
      <fieldset
        className={cx(
          styles.container,
          isDarkMode && styles.containerDark,
          (disabled || readOnly) && styles.disabled,
          large && styles.largeContainer,
          readOnly && styles.readOnly,
          prefix && styles.containerPrefixed,
          className,
        )}
      >
        <label
          className={cx(
            styles.label,
            isDarkMode && styles.labelDark,
            !floatLabel && large && styles.largeLabel,
            floatLabel && [
              styles.floatLabel,
              isDarkMode && styles.floatLabelDark,
            ],
          )}
          htmlFor={id}
        >
          {invalid ? errorMessage : label}
        </label>
        {prefix && (
          <span className={styles.prefix}>
            {prefix({ onFocus: () => null, onBlur: () => null })}
          </span>
        )}
        <input
          className={cx(
            styles.input,
            isDarkMode && styles.inputDark,
            large && styles.largeInput,
            invalid && styles.hasError,
            codeType && styles.codeType,
          )}
          {...{
            autoCapitalize,
            autoComplete,
            autoCorrect,
            autoFocus,
            disabled,
            inputMode,
            maxLength,
            minLength,
            name,
            onBlur,
            onChange,
            onFocus,
            onKeyDown,
            onKeyPress,
            pattern,
            readOnly,
            ref,
            required,
            type,
            value,
          }}
          aria-describedby={(note || noteIcon) && (noteId || `${id}-note`)}
          id={id}
          placeholder={placeholder}
          spellCheck='false'
        />
        {iconButtons && (
          <div className={styles.iconButtonWrapper}>
            {iconButtons.map((iconButton, i) =>
              React.cloneElement(iconButton, {
                className: styles.iconButton,
                key: i,
              }),
            )}
          </div>
        )}
        {(note || noteIcon) && (
          <span id={noteId || `${id}-note`} className={styles.noteContainer}>
            {noteIcon &&
              React.cloneElement(noteIcon, {
                className: cx(styles.noteIcon, noteIcon.props.className),
              })}
            {note && <div className={styles.note}>{note}</div>}
          </span>
        )}
        {children}
      </fieldset>
    );
  },
);

BaseInput.displayName = 'BaseInput';
export default BaseInput;
export { styles };

import React from 'react';

import IconButton from '../IconButton';
import AppearanceShow from '../Icons/AppearanceShow';
import AppearanceHide from '../Icons/AppearanceHide';
import BaseInput, { InputProps } from '../BaseInput';

import styles from './PasswordInput.module.scss';

interface Props extends InputProps {
  showPassword?: boolean;
  setShowPassword?: (showPassword: boolean) => void;
  useShowText?: boolean;
}

export const PasswordInput: React.ForwardRefExoticComponent<
  Props
> = React.forwardRef((props, ref) => {
  let icon = props.showPassword ? <AppearanceHide /> : <AppearanceShow />;
  if (props.useShowText) {
    icon = (
      <div className={styles.showPasswordText}>
        {props.showPassword ? 'Hide' : 'Show'}
      </div>
    );
  }
  const iconButton = (
    <IconButton
      accessibilityLabel='Toggle password visibility'
      icon={icon}
      onClick={() =>
        props.setShowPassword != null
          ? props.setShowPassword(!props.showPassword)
          : null
      }
      className={props.showPassword ? styles.iconPasswordShown : ''}
    />
  );

  const buttons = props.iconButtons
    ? [iconButton, ...props.iconButtons]
    : [iconButton];

  return (
    <BaseInput
      {...props}
      iconButtons={props.setShowPassword != null ? buttons : props.iconButtons}
      ref={ref}
      type={props.showPassword ? 'text' : 'password'}
    />
  );
});

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
export { styles };

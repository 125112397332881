import React from 'react';
import cx from 'classnames';

import BookOpen from '../Icons/BookOpen';
import Checkmark from '../Icons/Checkmark';
import ChevronS2Down from '../Icons/ChevronS2Down';
import Copy from '../Icons/Copy';
import IconButton from '../IconButton';
import Dropdown, { DropdownItem } from '../Dropdown';

import styles from './CodeBlock.module.scss';

export interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  code: string | null; // code can be null if children is passed
  isCopying?: boolean;
  wrap?: boolean;
  onClick?: () => void;
  title?: string;
  dropdown?: {
    isOpen: boolean;
    onChange: (v: DropdownItem) => void;
    onClick: () => void;
    onEscape?: () => void;
    options: Array<DropdownItem>;
    text: string;
    value: DropdownItem;
  };
  referenceLink?: {
    href: string;
    accessibilityLabel: string;
  };
}

export const CodeBlock: React.FC<Props> = ({
  children,
  className,
  code,
  dropdown,
  isCopying,
  wrap,
  onClick,
  title,
  referenceLink,
}) => (
  <div className={cx(styles.wrapper, className)} role='group'>
    {(title || onClick) && (
      <div className={styles.header}>
        <label className={styles.title}>{title}</label>
        <div className={styles.buttonWrapper}>
          {dropdown && (
            <Dropdown
              wrapperClassName={styles.dropdown}
              isOpen={dropdown.isOpen}
              options={dropdown.options}
              onChange={dropdown.onChange}
              onEscape={dropdown.onEscape}
              value={dropdown.value}
              target={
                <IconButton
                  className={styles.button}
                  accessibilityLabel={`Select ${dropdown.text}`}
                  onClick={dropdown.onClick}
                  icon={
                    <>
                      <span className={styles.dropdownText}>
                        {dropdown.text}
                      </span>
                      <ChevronS2Down />
                    </>
                  }
                />
              }
            />
          )}

          {referenceLink && (
            <IconButton
              className={cx(styles.button, styles.buttonIcon)}
              accessibilityLabel={referenceLink.accessibilityLabel}
              href={referenceLink.href}
              icon={
                <span className={styles.iconWrapper}>
                  <BookOpen />
                </span>
              }
            />
          )}
          {onClick && (
            <IconButton
              className={cx(
                styles.button,
                styles.buttonIcon,
                isCopying && styles.buttonActive,
              )}
              accessibilityLabel='Copy'
              onClick={onClick}
              icon={
                <>
                  <span className={styles.iconWrapper}>
                    <Copy
                      className={cx(
                        styles.iconCopy,
                        isCopying && styles.active,
                      )}
                    />
                    <Checkmark
                      className={cx(
                        styles.iconCheckmark,
                        isCopying && styles.active,
                      )}
                    />
                  </span>
                </>
              }
            />
          )}
        </div>
      </div>
    )}
    <div className={styles.scrollContainer}>
      {children != null ? (
        children
      ) : (
        <pre className={cx(styles.pre, wrap ? styles.wrap : styles.noWrap)}>
          <code className={cx(styles.code)}>{code}</code>
        </pre>
      )}
    </div>
  </div>
);

CodeBlock.displayName = 'CodeBlock';
export default CodeBlock;
export { styles };

import React from 'react';
import cx from 'classnames';

import ChevronS2Right from '../Icons/ChevronS2Right';
import Touchable, { TouchableProps } from '../Touchable';
import { styles as linkStyles } from '../PrimaryLink';
import styles from './ActionCard.module.scss';

/**
 * Titled card that is entirely clickable
 */
export interface Props extends TouchableProps {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  ctaText?: string;
  iconClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
}

export const ActionCard: React.FC<Props> = ({
  className,
  icon,
  title,
  subtitle,
  ctaText,
  ...restProps
}) => (
  <Touchable className={cx(styles.card, className)} {...restProps}>
    {icon && (
      <span
        className={cx(
          title == null && styles.iconSpacer,
          restProps.iconClassName,
        )}
      >
        {icon}
      </span>
    )}
    {title && (
      <h4 className={cx(styles.title, restProps.titleClassName)}>{title}</h4>
    )}
    {subtitle && (
      <p className={cx(styles.subtitle, restProps.subtitleClassName)}>
        {subtitle}
      </p>
    )}
    <span
      className={cx(styles.link, linkStyles.baseLink, linkStyles.primaryLink)}
    >
      {ctaText}
      <ChevronS2Right
        className={cx(styles.rightChevron, linkStyles.rightChevron)}
      />
    </span>
  </Touchable>
);

ActionCard.displayName = 'ActionCard';
export default ActionCard;
export { styles };

import React from 'react';
import cx from 'classnames';

import styles from './Grid.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  debug?: boolean;
  containerClassName?: Parameters<typeof cx>[0];
  fullWidth?: boolean;
  noMarginRightSmall?: boolean;
  noMarginRightMedium?: boolean;
  noMarginRightLarge?: boolean;
  noPadding?: boolean;
}

export const Grid: React.FC<Props> = ({
  children,
  containerClassName,
  debug,
  fullWidth,
  noMarginRightSmall,
  noMarginRightMedium,
  noMarginRightLarge,
  noPadding,
  className,
}) => (
  <div
    className={cx(
      containerClassName,
      styles.gridContainer,
      fullWidth && styles.fullWidth,
      noPadding && styles.noPadding,
    )}
  >
    <div
      className={cx(
        className,
        styles.grid,
        noMarginRightSmall && styles.noMarginRightSmall,
        noMarginRightMedium && styles.noMarginRightMedium,
        noMarginRightLarge && styles.noMarginRightLarge,
        debug && 'threadsGridDebug',
      )}
    >
      {children}
    </div>
  </div>
);

Grid.displayName = 'Grid';
export default Grid;
export { styles };

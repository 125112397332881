import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import BaseInput, { InputProps } from '../BaseInput';
import inputStyles from '../BaseInput/BaseInput.module.scss';
import { SUPPORTED_COUNTRIES } from '../constants';

interface Props extends InputProps {
  onCountryCodeChange?: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const PhoneInput: FunctionComponent<Props> = props => (
  <BaseInput
    {...props}
    type='tel'
    prefix={({ onFocus, onBlur }) => (
      // N.B. this is intentionally not a <Field> because we don't want the value
      // in the form data, it is only used to set and reset the phoneNumber
      // country code
      <select
        className={cx(
          inputStyles.input,
          inputStyles.select,
          inputStyles['prefix-input'],
        )}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={props.onCountryCodeChange}
      >
        {SUPPORTED_COUNTRIES.map(({ code, icon, name }) => (
          <option key={code} value={code}>
            +1
            {/* TODO: remove libphonenumber depdendency
            {icon} {name} (+{getCountryCallingCode(code)})
              */}
          </option>
        ))}
      </select>
    )}
  />
);

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
